<template>
  <div class="harvest-leaderboard__table-row">
    <div
      v-for="column in leaderboardColumns"
      :key="column.id"
      :class="`harvest-leaderboard__table-row__col harvest-leaderboard__table-row__col--${column.id}`"
      :style="column.style || `width: ${column.width}px`"
    >
      <span>{{ getValue(column.getter) }}</span>
      <button v-if="column.id === 'address'">
        <CopyIcon
          v-if="!copied"
          @click="copyToClipboard(getValue(column.getter))"
        />
        <CheckIcon v-else />
      </button>
      <a
        v-if="column.id === 'address'"
        :href="`${community.opensea_link}`"
        target="_blank"
      >
        <OpenseaIcon />
      </a>
    </div>
  </div>
</template>

<script setup>
import { toRefs, defineProps, computed, ref } from "vue";
import { leaderboardColumns } from "@/store/harvest-leaderboard";
import CopyIcon from "@/assets/icons/copy.svg?inline";
import CheckIcon from "@/assets/icons/check.svg?inline";
import OpenseaIcon from "@/assets/icons/opensea.svg?inline";

const props = defineProps({
  community: {
    type: Object,
    required: true,
  },
});


const copied = ref(false);

const getValue = (getter) => {
  return computed(() => {
    if (!props.community) return "";
    if (typeof getter === "function") {
      return getter(props.community);
    } else {
      return props.community[getter];
    }
  }).value;
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  copied.value = true;

  setTimeout(() => {
    copied.value = false;
  }, 2000);
};
</script>
