const leaderboardColumns = [
  {
    id: "name",
    label: "Name",
    getter: "name",
    align: "left",
    sortable: false,
    style: {
      textAlign: "left",
      width: "200px",
    },
  },
  {
    id: "token_ids_count",
    label: "# of Assets Harvested",
    getter: "token_ids_count",
    sortable: true,
    style: {
      textAlign: "center",
      width: "120px",
    },
  },
  {
    id: "wallet_addresses",
    label: "Wallets Harvesting",
    getter: "wallet_addresses",
    align: "center",
    sortable: true,
    style: {
      textAlign: "center",
      width: "120px",
    },
  },
  {
    id: "meth_amount",
    label: "Total Harvested METH",
    getter: "meth_amount",
    align: "center",
    sortable: true,
    style: {
      textAlign: "center",
      width: "120px",
    },
  },
  {
    id: "methHarvestedPerWallet",
    label: "METH Harvested per Wallet",
    getter: (row) => Math.floor(row.meth_amount / row.wallet_addresses),
    sortable: true,
    style: {
      textAlign: "center",
      width: "120px",
    },
  },
  {
    id: "address",
    label: "Address",
    getter: "address",
    sortable: false,
    style: {
      textAlign: "right",
      width: "200px",
    },
  },
];

export default leaderboardColumns;
