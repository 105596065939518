<template>
  <div class="harvest-leaderboard__table-header">
    <div
      v-for="column in leaderboardColumns"
      :key="column.id"
      class="harvest-leaderboard__table-header__col"
      :style="column.style"
    >
      <span>{{ column.label }}</span>
      <button
        v-if="column.sortable"
        type="button"
        @click="() => sortBy(column.id)"
      >
        <ArrowUp
          v-if="sort.direction === 'asc' && sort.id === column.id"
          class="up"
        />
        <ArrowDown
          v-else-if="sort.direction === 'desc' && sort.id === column.id"
          class="down"
        />
        <ArrowUpDown v-else />
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  leaderboardColumns,
  useHarvestLeaderboard,
} from "@/store/harvest-leaderboard";
import { computed } from "vue";

import ArrowUp from "@/assets/icons/up.svg?inline";
import ArrowDown from "@/assets/icons/down.svg?inline";
import ArrowUpDown from "@/assets/icons/arrow-up-down.svg?inline";

const sort = computed(() => useHarvestLeaderboard().sort);
const sortBy = computed(() => useHarvestLeaderboard().sortBy);
</script>
