const getters = {
  _currentTotalRows() {
    let data = [];
    if (this.currentView === "seven_days") {
      data = this.sevenDaysHarvest;
    } else if (this.currentView === "yesterday") {
      data = this.yesterdayHarvest;
    } else {
      data = this.totalHarvest;
    }
    return data;
  },
  currentPage() {
    return this._currentTotalRows.slice(
      (this.page - 1) * this.rowsPerPage,
      this.page * this.rowsPerPage
    );
  },
  stats() {
    const data = {
      totalNFTHarvest: this._currentTotalRows.reduce(
        (acc, curr) => acc + curr.token_ids_count,
        0
      ),
      totalProjectsHarvest: this._currentTotalRows.length,
      totalMethHarvest: this._currentTotalRows.reduce(
        (acc, curr) => acc + curr.meth_amount,
        0
      ),
    };
    return data;
  },
};

export default getters;
