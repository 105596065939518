<template>
  <div class="harvest-leaderboard__table-footer">
    <div class="harvest-leaderboard__table-footer__rows-per-page">
      <p>Rows Per Page</p>
      <select
        v-model="rowsPerPage"
        @change="changeRowsPerPage"
      >
        <option value="25">
          25
        </option>
        <option value="50">
          50
        </option>
        <option value="100">
          100
        </option>
      </select>
    </div>
    <div class="harvest-leaderboard__table-footer__pagination">
      <button
        type="button"
        @click="firstPage"
      >
        <IChevronDoubleLeft />
      </button>
      <button
        type="button"
        @click="prevPage"
      >
        <IChevronLeft />
      </button>
      <p>{{ page }}</p>
      <button
        type="button"
        @click="nextPage"
      >
        <IChevronRight />
      </button>
      <button
        type="button"
        @click="lastPage"
      >
        <IChevronDoubleRight />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useHarvestLeaderboard } from "@/store/harvest-leaderboard";
import { computed } from "vue";
import IChevronDoubleLeft from "@/assets/icons/chevron-double-left.svg?inline";
import IChevronLeft from "@/assets/icons/chevron-left.svg?inline";
import IChevronRight from "@/assets/icons/chevron-right.svg?inline";
import IChevronDoubleRight from "@/assets/icons/chevron-double-right.svg?inline";

const page = computed(() => useHarvestLeaderboard().page);
const rowsPerPage = computed(() => useHarvestLeaderboard().rowsPerPage);

const nextPage = () => {
  useHarvestLeaderboard().nextPage();
};

const prevPage = () => {
  useHarvestLeaderboard().prevPage();
};

const firstPage = () => {
  useHarvestLeaderboard().changePage(1);
};

const lastPage = () => {
  useHarvestLeaderboard().changePage(useHarvestLeaderboard().pages);
};

const changeRowsPerPage = (e) => {
  useHarvestLeaderboard().changeRowsPerPage(e.target.value);
};
</script>
