<template>
  <div class="harvest-leaderboard">
    <h1 class="harvest-leaderboard__title">
      Harvest Leaderboard
    </h1>
    <LeaderboardActions />
    <div class="harvest-leaderboard__wrapper">
      <LeaderboardHeader />
      <div class="harvest-leaderboard__table">
        <LoadingSpinner v-if="isLoading" />
        <LeaderboardRow
          v-for="row in page"
          :key="row.id"
          :community="row"
        />
      </div>
      <LeaderboardFooter />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { getHarvestLeaderboard as queryFn } from "@/functions/getHarvestLeaderboard";
import { useHarvestLeaderboard } from "@/store/harvest-leaderboard";
import LeaderboardRow from "@/components/HarvestLeaderboard/LeaderboardRow.vue";
import LeaderboardHeader from "@/components/HarvestLeaderboard/LeaderboardHeader.vue";
import LeaderboardFooter from "@/components/HarvestLeaderboard/LeaderboardFooter.vue";
import LeaderboardActions from "@/components/HarvestLeaderboard/LeaderboardActions.vue";
import LoadingSpinner from "@/assets/img/spinner-clear.svg?inline";

const { isLoading, error } = useQuery({
  queryFn,
  queryKey: ["harvest-leaderboard"],
  refetchInterval: 1000 * 60 * 5,
  onSuccess: (data) => {
    useHarvestLeaderboard().setData(data.data);
  },
});

const page = computed(() => useHarvestLeaderboard().currentPage);
const stats = computed(() => useHarvestLeaderboard().stats);
</script>

<style lang="sass">
@import "@/assets/scss/harvest-leaderboard.scss"
</style>
