<template>
  <div class="harvest-leaderboard__actions">
    <!--<div class="harvest-leaderboard__actions__item">
      <div class="harvest-leaderboard__actions__item__label">
        Total Communitites
      </div>
      <div class="harvest-leaderboard__actions__item__value">
        {{ stats.totalCommunities }}
      </div>
    </div>
    <div class="harvest-leaderboard__actions__item">
      <div class="harvest-leaderboard__actions__item__label">
        Total NFTs Harvested
      </div>
      <div class="harvest-leaderboard__actions__item__value">
        {{ formatter.format(stats.totalNFTHarvest) }}
      </div>
    </div>
    <div class="harvest-leaderboard__actions__item">
      <div class="harvest-leaderboard__actions__item__label">
        Total $METH Harvested
      </div>
      <div class="harvest-leaderboard__actions__item__value">
        {{ formatter.format(stats.totalMethHarvest) }}
      </div>
    </div>-->
    <div class="harvest-leaderboard__actions__timeframe">
      <button
        type="button"
        class="harvest-leaderboard__actions__timeframe__button"
        :aria-active="currentView === 'total'"
        @click="changeTimeframe('total')"
      >
        Total Lifetime Harvest
      </button>
      <button
        type="button"
        class="harvest-leaderboard__actions__timeframe__button"
        :aria-active="currentView === 'yesterday'"
        @click="changeTimeframe('yesterday')"
      >
        Yesterday Harvest
      </button>
      <button
        type="button"
        class="harvest-leaderboard__actions__timeframe__button"
        :aria-active="currentView === 'seven_days'"
        @click="changeTimeframe('seven_days')"
      >
        Seven Days Harvest
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useHarvestLeaderboard } from "@/store/harvest-leaderboard";

const stats = computed(() => useHarvestLeaderboard().stats);
const currentView = computed(() => useHarvestLeaderboard().currentView);
const formatter = new Intl.NumberFormat("en", {
  notation: "compact",
});

const changeTimeframe = (timeframe) => {
  useHarvestLeaderboard().setTimeframe(timeframe);
};
</script>
