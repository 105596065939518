const actions = {
  setData(data) {
    this.totalHarvest = data.data.total;
    this.yesterdayHarvest = data.data.yesterday;
    this.sevenDaysHarvest = data.data.seven_days;
    this.pages = Math.ceil(this.totalHarvest.length / this.rowsPerPage);
  },
  setTimeframe(timeframe) {
    this.currentView = timeframe;
  },
  changeRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
    this.pages = Math.ceil(this.totalHarvest.length / this.rowsPerPage);
  },
  changePage(page) {
    if (page < 1 || page > this.pages) return;
    this.page = page;
  },
  nextPage() {
    if (this.page < this.pages) this.page++;
  },
  prevPage() {
    if (this.page > 1) this.page--;
  },
  sortBy(id) {
    if (this.sort.id === id) {
      this.sort.direction = this.sort.direction === "asc" ? "desc" : "asc";
    } else {
      this.sort.id = id;
      this.sort.direction = "asc";
    }

    if (this.sort.direction === "asc") {
      if (id === "methHarvestedPerWallet") {
        this._currentTotalRows.sort((a, b) => {
          if (
            a.meth_amount / a.wallet_addresses >
            b.meth_amount / b.wallet_addresses
          )
            return 1;
          if (
            a.meth_amount / a.wallet_addresses <
            b.meth_amount / b.wallet_addresses
          )
            return -1;
        });
      } else {
        this._currentTotalRows.sort((a, b) => {
          if (a[id] < b[id]) return -1;
          if (a[id] > b[id]) return 1;
        });
      }
      return 0;
    } else {
      this._currentTotalRows.sort((a, b) => {
        if (id === "methHarvestedPerWallet") {
          if (
            a.meth_amount / a.wallet_addresses >
            b.meth_amount / b.wallet_addresses
          )
            return -1;
          if (
            a.meth_amount / a.wallet_addresses <
            b.meth_amount / b.wallet_addresses
          )
            return 1;
        } else {
          if (a[id] > b[id]) return -1;
          if (a[id] < b[id]) return 1;
        }
        return 0;
      });
    }
  },
};

export default actions;
