import { defineStore } from "pinia";

import actions from "./leaderboard-actions";
import getters from "./leaderboard-getters";

const state = {
  totalHarvest: [],
  yesterdayHarvest: [],
  sevenDaysHarvest: [],
  currentView: "total",
  rowsPerPage: 25,
  page: 1,
  pages: 1,
  sort: {
    id: "",
    direction: "",
  },
  stats: {
    totalNFTHarvest: "",
    totalMethHarvest: "",
    totalCommunities: "",
  },
};

const useHarvestLeaderboard = defineStore("harvest-leaderboard", {
  state: () => state,
  getters,
  actions,
});

export default useHarvestLeaderboard;
